@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply min-w-full bg-gray-200 font-main;
}

@supports (-moz-appearance:none) {
  body {
    @apply font-sans !important
  }
}

h2 {
  @apply text-2xl font-bold max-mobile:text-base
}

h3 {
  @apply text-xl font-bold max-mobile:text-sm
}

a, input, button {
  @apply outline-none;
}

.btn {
  @apply p-2 font-bold text-white transition duration-500 ease-out bg-orange-400 border-2 border-orange-400 rounded-lg shadow-md outline-none cursor-pointer hover:bg-orange-600 hover:border-orange-600 focus:outline-none;
}

#file-upload-button {
  @apply bg-orange-200 text-orange-400
}

.error {
  @apply p-2 text-center text-red-500 bg-red-100 border border-red-500;
}

/* Disable arrows on number-type inputs */

/* Chrome, Safari */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: none;
  -moz-appearance: textfield; 
}

/* Tables */
#products-table td, #users-table td {
 @apply max-tablet:flex max-tablet:justify-center max-tablet:!w-full max-tablet:border-b max-tablet:border-orange-200 max-tablet:text-sm
}
td {
  @apply text-center 
}
tr {
  @apply h-14
}
tr:nth-child(odd) {
  @apply bg-orange-100
}
thead td {
  @apply bg-orange-400
}

/* Replace original checkbox style */
input[type="checkbox"] {
  @apply hidden
}

input[type="checkbox"] + span {
  @apply relative inline-block align-middle transition duration-300 ease-out bg-white border border-black rounded-md cursor-pointer min-w-6 min-h-6 top-px;
}

input[type="checkbox"]:checked + span {
  @apply bg-orange-400 border-white;
}

input[type="checkbox"]:checked + span + label {
  @apply text-orange-400 transition duration-300 ease-out;
}

/* Carousel items */
.react-multi-carousel-track > li {
  @apply pb-7 max-mobile:pb-4
}

.react-multiple-carousel__arrow {
  @apply z-0 
}

/* Navbar */
.dropdown-link {
  @apply px-2 text-white rounded cursor-pointer hover:font-bold;
}